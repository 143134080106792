.SortableItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.DragHandle {
  display: flex;
  width: 12px;
  padding: 15px;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  touch-action: none;
  cursor: var(--cursor, pointer);
  border-radius: 5px;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}

.DragHandle:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.DragHandle:focus-visible {
  box-shadow: 0 0px 0px 2px #4c9ffe;
}

.DragHandle svg {
  flex: 0 0 auto;
  margin: auto;
  height: 100%;
  overflow: visible;
  fill: #919eab;
}