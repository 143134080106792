.webhook {
    .action-bar {
        padding: 15px 24px;
        background: #f5f5f5;
        height: 60px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #E7EEF7;
        text-align: right;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .filter {
            flex-grow: unset;
            width: 370px;
            height: 40px;
            border-radius: 6px;
            border: 1px solid #D9D9D9; } }
    .sm-container {
        border-radius: 4px;
        border: 1px solid #D8DEE6;
        background: #FFF;
        .header {
            height: 40px;
            font-size: 14px;
            letter-spacing: 0px;
            color: #404040;
            padding: 0px;
            margin-top: 15px;
            margin-left: 30px;
            margin-right: 30px; }
        .list {
            overflow-y: auto;
            height: calc( 100vh - 220px );
            .item:hover {
                background: #f5f5f5;
                cursor: pointer; }
            .item {
                cursor: default;
                background: #fff;
                padding: 0px 30px;
                display: flex;
                align-items: center;
                height: 64px;
                border-top: 1px solid #f1f1f1; } } } }
.save_webhook {
    .ant-drawer-body, .ant-drawer-footer {
        background: #FFF; }
    .ant-drawer-body {
        padding: 0px;
        overflow: hidden; }
    .header {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #E5E5E5;
        padding: 0px 17px;
        .title {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #000000;
            margin-left: 16px; }
        .arrow {
            cursor: pointer;
            color: #000;
            font-size: 24px; } }
    .content {
        padding: 20px 17px;
        display: flex;
        gap: 18px;
        justify-content: center;
        .secret-key input {
            height: 30px; }
        .secret-key input:focus {
            border: none; }
        .secret-key {
            .text {
                font-weight: 500;
                font-size: 13px;
                color: #121212;
                cursor: pointer; } }
        .activation-status {
            color: #797979;
            font-size: 13px;
            font-weight: 500;
            width: 554px; }
        .section-webhook {
            width: 685px;
            height: 434px;
            padding: 16px 14px;
            border-radius: 8px;
            border: 1px solid #D9D9D9;
            background: #FFF;
            box-shadow: 0px 4px 16px 0px rgba(198, 198, 198, 0.12); }
        .group {
            display: flex;
            margin-bottom: 20px;
            flex-direction: column;
            justify-content: flex-start; }
        .label {
            font-size: 16px;
            letter-spacing: 0px;
            color: #4E4E4E;
            margin-bottom: 8px; }
        .ant-input {
            height: 40px; }
        .ant-input:focus, .ant-input-focused {
            border: 1px solid #121212; } }
    .events-container {
        width: 482px;
        padding: 15px;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid #D9D9D9;
        background: #FFF;
        box-shadow: 0px 4px 16px 0px rgba(198, 198, 198, 0.12);
        .events-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 8px;
            margin-bottom: 12px;
            h2 {
                font-size: 18px;
                font-weight: 600; }
            .clear-button {
                font-size: 14px;
                color: #007bff;
                cursor: pointer;
                background: none;
                border: none;
                &:hover {
                    text-decoration: underline; } } }
        .events {
            border-radius: 6px;
            border: 1px solid #D9D9D9;
            background: #FFF;
            margin: 12px 0px;
            .search-container {
                display: flex;
                align-items: center;
                margin-bottom: 12px;
                border-bottom: 1px solid #D9D9D9;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                .search-input {
                    flex-grow: 1;
                    padding: 10px 14px;
                    border: none;
                    height: 42px; }
                .clear-search {
                    background: none;
                    border: none;
                    font-size: 16px;
                    color: #888;
                    cursor: pointer;
                    width: 34px;
                    &:hover {
                        color: #555; } } }
            .event-list {
                height: 288px;
                overflow-y: auto;
                .event-group {
                    margin-bottom: 10px;
                    padding: 1px 12px;
                    .checkbox {
                        margin-right: 8px;
                        width: 20px;
                        height: 20px;
                        border-radius: 4px;
                        border: 1px solid #D9D9D9;
                        background: #FFF;
                        padding: 1px;
                        cursor: pointer;
                        .selected {
                            background: #121212;
                            width: 100%;
                            height: 100%;
                            border-radius: 4px;
                            cursor: pointer; } }
                    .group-label {
                        font-weight: 600;
                        display: flex;
                        align-items: center;
                        margin-bottom: 11px; }
                    .event-label {
                        display: flex;
                        align-items: center;
                        margin-left: 16px;
                        margin-bottom: 11px; } } } } } }
