.promotion {
    // padding: 14px 28px
    // .ant-select
    //     min-width: 200px
    //     border-radius: 5px
    //     border-width: 0px
    //     .ant-select-multiple .ant-select-selector, .ant-select-selector
    //         border-radius: 5px !important
    .ant-select-selector {
        display: flex;
        align-items: center;
        justify-content: center; }
    .head {
        font: 14px/19px AvenirMedium;
        letter-spacing: 0px;
        color: #000000;
        height: 26px;
        .link {
            font: 12px/16px AvenirMedium;
            letter-spacing: 0px;
            color: #11A1FD;
            cursor: pointer; }
        .path-arrow {
            color: #697B95;
            font-size: 12px;
            padding: 0px 8px 0px 4px; } }
    .title {
        font: normal normal 900 18px/25px AvenirMedium;
        letter-spacing: 0px;
        color: #000000; }
    .list {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #96A9C340;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        .action-bar {
            background: #f5f5f5;
            height: 60px;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: #E7EEF7;
            text-align: right;
            padding: 0px 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .actions {
                display: flex;
                justify-content: space-between;
                align-items: center; }
            .title {
                font: 14px AvenirMedium;
                letter-spacing: 0px;
                color: #404040;
                float: left;
                margin: 10px 25px;
                line-height: 2em; }
            .multi-select {
                width: 240px; }
            .btn {
                // border-radius: 5px
                // height: 30px
                // font-size: 12px
                // width: 180px
                margin: 0px 10px;
                // color: #fff
                // background: #000000 0% 0% no-repeat padding-box
                // padding: 0px
                // font-weight: 500
                // letter-spacing: -0.032em
                // box-shadow: 0px 0px 12px #96a9c340
                // border: 0px
                // font-family: 'AvenirMedium'
 } }                // line-height: 2rem
        .content {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            .header {
                font: 14px AvenirMedium;
                letter-spacing: 0px;
                color: #404040;
                padding: 0px;
                margin-top: 15px;
                margin-left: 30px;
                margin-right: 30px;
                .ant-col {
                    padding-right: 20px; } }
            .filters {
                margin-top: 7px;
                margin-left: 30px;
                margin-bottom: 15px;
                margin-right: 30px;
                .date-range {
                    position: relative;
                    background: #FFFFFF;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    height: 40px;
                    padding-left: 20px;
                    display: flex;
                    justify-content: center;
                    .ant-picker {
                        border: none; }
                    .ant-picker-input {
                        width: 108px; }
                    .ant-picker-range .ant-picker-active-bar {
                        height: 0px; } }
                .ant-input-suffix {
                    @media screen and (min-width: 900px) and (max-width: 1400px) {
                        line-height: 1; }
                    @media screen and (min-width: 1400px) {
                        line-height: 2; }
                    img {
                        @media screen and (min-width: 900px) and (max-width: 1400px) {
                            width: 15px;
                            height: 20px; }
                        @media screen and (min-width: 1400px) {
                            width: 20px;
                            height: 24px; } } }
                .ant-col {
                    padding-right: 20px; }
                // .ant-input-affix-wrapper
                //     background: #FFFFFF 0% 0% no-repeat padding-box
                //     border: 1px solid #ccc
                //     border-radius: 5px
                // .ant-select
                //     width: 100%
                //     height: 34px
                //     .ant-select-selector
                //         height: 30px
 }                //         border-radius: 0px
            .contains {
                overflow-y: auto;
                // margin-left: 30px
                margin-bottom: 15px;
                margin-top: 15px;
                // margin-right: 30px
                .item {
                    padding: 15px 30px;
                    display: flex;
                    align-items: center;
                    border-top: 1px solid #f1f1f1;
                    .ant-col {
                        // padding: 10px 0px
                        opacity: 1;
                        padding-right: 20px; }
                    .ant-col:hover {
                        opacity: 0.6;
                        cursor: pointer; }
                    .category {
                        padding: 0px;
                        .ant-badge {
                            margin: 0px 4px; }
                        .ant-badge-not-a-wrapper .ant-badge-count {
                            background: #d6e6f2;
                            color: #000;
                            border-radius: 4px;
                            height: 26px;
                            padding: 3px 10px; } }
                    .instock {
                        color: #07C180; }
                    .outOfStock {
                        color: #ED1536;
                        font-weight: 600; } } } }
        .active {
            width: 2px;
            height: 100%;
            background-color: #46b275;
            float: left;
            top: 0px;
            left: 0px;
            position: absolute; }
        .inactive {
            width: 2px;
            height: 100%;
            background-color: #a8abbd;
            float: left;
            top: 0px;
            left: 0px;
            position: absolute; } } }
.promotion-view {
    // padding: 0px 28px
    .hide-from-list {
        color: #4E4E4E;
        font-size: 14px;
        font-weight: 500; }
    .ant-picker.ant-picker-disabled {
        background: #FFF; }
    .ant-drawer-body, .ant-drawer-footer {
        background: #FFF; }
    .ant-drawer-body {
        padding: 0px;
        overflow: hidden; }
    .header {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #E5E5E5;
        padding: 0px 17px;
        .title {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #000000;
            margin-left: 16px; }
        .arrow {
            cursor: pointer;
            color: #000;
            font-size: 24px; } }
    .content {
        width: 100%;
        height: 88vh;
        overflow-y: auto;
        padding: 12px;
        background: #F7F7F7;
        display: flex;
        .rules {
            display: flex;
            flex-direction: column;
            height: 100%;
            .op, .ant-select-selector {
                width: 100px;
                display: inline-flex;
                padding: 9px 10px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 6px;
                border: 1px solid #9A7BFF;
                background: #ECE6FF;
                color: #9A7BFF;
                font-size: 14px;
                font-weight: 500; }
            .rule {
                margin: 8px 0px;
                padding: 12px;
                border-radius: 8px;
                border: 1px solid #D9D9D9;
                background: #FFF;
                box-shadow: 0px 4px 16px 0px rgba(198, 198, 198, 0.12); } }
        .empty-list {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%; }
        .selected-prod-list {
            display: flex;
            justify-content: start;
            flex-wrap: wrap;
            max-height: 128px;
            overflow-y: auto;
            .item {
                width: 310px;
                padding: 4px;
                display: flex;
                border-radius: 6px;
                border: 1px solid #D9D9D9;
                box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
                align-items: center;
                justify-content: space-between;
                margin-right: 20px;
                margin-bottom: 20px;
                height: 48px;
                .delete {
                    height: 16px;
                    cursor: pointer; }
                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 4px; }
                .title {
                    color: #121212;
                    font-size: 13px;
                    font-weight: 500;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis; }
                .subtitle {
                    color: #797979;
                    font-size: 11px;
                    font-weight: 500;
                    margin-right: 4px; }
                .qty {
                    color: #121212;
                    font-size: 11px;
                    font-weight: 500; } } }
        .free-products {
            .ant-select {
                border: none;
                border-radius: 0px; }
            .product-qty {
                border: 1px solid #ccc;
                border-radius: 5px;
                display: flex;
                background: #FFF; }
            .qty {
                display: flex;
                align-items: center;
                border-left: 1px solid #ccc;
                padding-left: 8px;
                color: #6E7074;
                font-size: 13px;
                font-weight: 500;
                input {
                    border: none;
                    width: 40px; } } }
        .section {
            padding: 20px 15px;
            flex-shrink: 0;
            border-radius: 8px;
            border: 1px solid #D9D9D9;
            background: #FFF;
            box-shadow: 0px 4px 16px 0px rgba(198, 198, 198, 0.12); }
        .group {
            margin: 0px;
            .date-range {
                position: relative;
                background: #FFFFFF;
                border: 1px solid #ccc;
                border-radius: 5px;
                height: 40px;
                padding-left: 20px;
                display: flex;
                justify-content: center;
                .ant-picker {
                    border: none; }
                .ant-picker-input {
                    width: 108px; }
                .ant-picker-range .ant-picker-active-bar {
                    height: 0px; } }
            .label {
                height: 19px;
                text-align: left;
                font-size: 16px;
                letter-spacing: 0px;
                color: #4E4E4E;
                margin-bottom: 8px; }
            .value {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #ADB7C3;
                border-radius: 4px;
                resize: none;
                width: 100%;
                img {
                    cursor: pointer; } }
            // .outlets
            //     height: 200px
            //     overflow-y: auto
            //     .item
            //         background: #FFFFFF 0% 0% no-repeat padding-box
            //         border: 1px solid #ADB7C3
            //         border-radius: 5px
            //         padding: 16px 10px
            //         .name
            //             font: 16px/22px AvenirMedium
            //             letter-spacing: -0.51px
            //             color: #071E3D
            //         .label
            //             font: 14px/22px AvenirMedium
            //             letter-spacing: -0.45px
            //             color: #576B83
            //         .value
            //             font: 14px/22px AvenirMedium
            //             letter-spacing: -0.45px
            //             color: #00BDFF
            //             border: none
            //         .instock
            //             font: 14px/22px AvenirMedium
            //             letter-spacing: -0.45px
            //             color: #07C180
            //         .outstock
            //             font: 14px/22px AvenirMedium
            //             letter-spacing: -0.45px
 }            //             color: #ED1536
        .line {
            border: 1px solid #E7EEF7;
            width: 100%;
            height: 0px; }
        .action-bar {
            text-align: left;
            padding-left: 40px;
            // .save
            //     width: 165px
            //     background: #FFF
            //     color: #000
            .error {
                color: #ED1536;
                font-size: 15px;
                letter-spacing: 0px;
                font-weight: 800;
                float: left;
                padding: 12px 26px; }
            // .submit
            //     width: 146px
            //     height: 40px
            //     background: #11A1FD 0% 0% no-repeat padding-box
            //     font-size: 15px
            //     letter-spacing: 0px
            //     color: #FFFFFF
            //     border-radius: 5px
            // .cancel
            //     margin-right: 40px
            //     border: none
            //     font-size: 15px
            //     letter-spacing: 0px
 } } }            //     color: #7D9EB5
.rule-builder-modal {
    position: absolute;
    top: 0;
    right: 0;
    width: 75%;
    border-radius: 0px;
    height: 100vh !important;
    line-height: 0px;
    margin: 0px;
    padding: 0px;
    .rule-builder {
        .wrapper {
            .body {
                padding: 15px; } } }
    .ant-modal-content {
        box-shadow: none;
        position: relative;
        height: 100%;
        .ant-modal-body {
            height: 100%; } } }
