.outlet {
    .ant-select-selector {
        display: flex;
        align-items: center;
        justify-content: center; }
    .menu-option {
        position: absolute;
        background: #fff;
        display: flex;
        flex-direction: column;
        text-align: left;
        box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
        padding: 14px 20px 0px;
        z-index: 9999;
        opacity: 1; }
    .head {
        font: 14px/19px AvenirMedium;
        letter-spacing: 0px;
        color: #000000;
        height: 26px;
        .link {
            font: 12px/16px AvenirMedium;
            letter-spacing: 0px;
            color: #11A1FD;
            cursor: pointer; }
        .path-arrow {
            color: #697B95;
            font-size: 12px;
            padding: 0px 8px 0px 4px; } }
    .title {
        font: 14px AvenirMedium !important;
        font: 14px AvenirMedium;
        letter-spacing: 0px;
        color: #404040;
        float: left;
        margin: 10px 25px;
        line-height: 2em; }
    .list {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #96A9C340;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        .action-bar {
            background: #f5f5f5;
            height: 60px;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: #E7EEF7;
            text-align: right;
            padding: 0px 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .actions {
                display: flex;
                justify-content: space-between;
                align-items: center; }
            // .title
            //     font: 14px AvenirMedium
            //     letter-spacing: 0px
            //     color: #404040
            //     float: left
            //     margin: 10px 25px
            //     line-height: 2em
            .multi-select {
                width: 240px; }
            .btn {
                // border-radius: 5px
                // height: 30px
                // font-size: 12px
                // width: 180px
                margin: 0px 10px;
                // color: #fff
                // background: #000000 0% 0% no-repeat padding-box
                // padding: 0px
                // font-weight: 500
                // letter-spacing: -0.032em
                // box-shadow: 0px 0px 12px #96a9c340
                // border: 0px
                // font-family: 'AvenirMedium'
 } }                // line-height: 2rem
        .content {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            .header {
                font: 14px AvenirMedium;
                letter-spacing: 0px;
                color: #404040;
                padding: 0px;
                margin-top: 15px;
                margin-left: 30px;
                margin-right: 30px;
                .ant-col {
                    padding-right: 20px; } }
            .filters {
                margin-top: 7px;
                margin-left: 30px;
                margin-bottom: 15px;
                margin-right: 30px;
                .ant-input-suffix {
                    @media screen and (min-width: 900px) and (max-width: 1400px) {
                        line-height: 1; }
                    @media screen and (min-width: 1400px) {
                        line-height: 2; }
                    img {
                        @media screen and (min-width: 900px) and (max-width: 1400px) {
                            width: 15px;
                            height: 20px; }
                        @media screen and (min-width: 1400px) {
                            width: 20px;
                            height: 24px; } } }
                .ant-col {
                    padding-right: 20px; }
                // .ant-input-affix-wrapper
                //     background: #FFFFFF 0% 0% no-repeat padding-box
                //     border: 1px solid #ccc
                //     border-radius: 5px
                // .ant-select
                //     width: 100%
                //     height: 34px
                //     .ant-select-selector
                //         height: 30px
 }                //         border-radius: 0px
            .contains {
                overflow-y: auto;
                .item {
                    padding: 0px 30px;
                    display: flex;
                    align-items: center;
                    height: 56px;
                    border-top: 1px solid #f1f1f1;
                    .ant-col {
                        // padding: 10px 0px
                        padding-right: 20px; }
                    .record {
                        opacity: 1; }
                    .record:hover {
                        opacity: 0.6;
                        cursor: pointer; } } } }
        .active {
            width: 2px;
            height: 100%;
            background-color: #46b275;
            float: left;
            top: 0px;
            left: 0px;
            position: absolute; }
        .inactive {
            width: 2px;
            height: 100%;
            background-color: #a8abbd;
            float: left;
            top: 0px;
            left: 0px;
            position: absolute; } } }
.outlet-detail {
    height: 100%;
    .action-bar {
        background: #f5f5f5;
        height: 60px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #E7EEF7;
        text-align: right;
        padding: 15px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center; }
    .header-title {
        font: 14px AvenirMedium;
        letter-spacing: 0px;
        color: #404040;
        float: left;
        margin: 10px 25px;
        line-height: 2em; }
    .contents {
        flex-wrap: wrap;
        display: flex;
        padding: 36px;
        .card {
            padding: 20px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #EFEFEF;
            border-radius: 10px;
            margin: 0px 0px 16px 0px;
            .title {
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0px;
                color: #000000; }
            .label {
                font-size: 16px;
                letter-spacing: 0px;
                color: #4E4E4E; }
            .value {
                font-size: 16px;
                letter-spacing: 0px;
                color: #121212; }
            .store-name {
                color: #188FFC;
                font-size: 16px;
                letter-spacing: 0px; } }
        .map {
            overflow: hidden;
            border: 1px solid #EFEFEF;
            border-radius: 10px; } }

    // padding: 0px 28px
    // .address-locator
    //     margin: 0px 20px
    //     box-shadow: 0px 0px 10px #96A9C340
    //     border-radius: 2px
    //     overflow: hidden
    // .header
    //     font-size: 12px
    //     padding: 22px 0px
    //     .link
    //         cursor: pointer
    //         font: 14px AvenirMedium
    //         letter-spacing: 0px
    //         color: #11A1FD
    //     .head
    //         font: 14px AvenirMedium
    //         letter-spacing: 0px
    //         color: #000000
    //     .title
    //         font: normal normal 900 18px AvenirMedium
    //         letter-spacing: 0px
    //         color: #000000
    // .content
    //     background: #FFFFFF 0% 0% no-repeat padding-box
    //     box-shadow: 0px 0px 10px #96A9C340
    //     border-radius: 5px
    //     width: 100%
    //     height: 100%
    //     .details
    //         padding: 36px 40px
    //         .basic-info
    //             .title
    //                 font: 16px AvenirMedium
    //                 letter-spacing: 0px
    //                 color: #000000
    //             .view-products
    //                 background: #34E5C1 0% 0% no-repeat padding-box
    //                 box-shadow: 0px 0px 12px #96A9C340
    //                 border-radius: 4px
    //                 font: 12px AvenirMedium
    //                 letter-spacing: -0.38px
    //                 color: #FFFFFF
    //                 text-transform: capitalize
    //                 margin-right: 10px
    //             .update-outlet
    //                 background: #000000 0% 0% no-repeat padding-box
    //                 box-shadow: 0px 0px 12px #96A9C340
    //                 border-radius: 4px
    //                 font: 12px AvenirMedium
    //                 letter-spacing: -0.38px
    //                 color: #FFFFFF
    //                 text-transform: capitalize
    //         .group
    //             margin: 16px 0px
    //             .label
    //                 font: 14px AvenirMedium
    //                 letter-spacing: 0px
    //                 color: #ADB7C3
    //             .value
    //                 font: 14px AvenirMedium
    //                 letter-spacing: 0px
    //                 color: #000000
    //                 img
    //                     cursor: pointer
    //             .outlets
    //                 height: 200px
    //                 overflow-y: auto
    //                 .item
    //                     background: #FFFFFF 0% 0% no-repeat padding-box
    //                     border: 1px solid #ADB7C3
    //                     border-radius: 5px
    //                     padding: 16px 10px
    //                     .name
    //                         font: 16px AvenirMedium
    //                         letter-spacing: -0.51px
    //                         color: #071E3D
    //                     .label
    //                         font: 14px AvenirMedium
    //                         letter-spacing: -0.45px
    //                         color: #576B83
    //                     .value
    //                         font: 14px AvenirMedium
    //                         letter-spacing: -0.45px
    //                         color: #00BDFF
    //                         border: none
    //                     .instock
    //                         font: 14px AvenirMedium
    //                         letter-spacing: -0.45px
    //                         color: #07C180
    //                     .outstock
    //                         font: 14px AvenirMedium
    //                         letter-spacing: -0.45px
    //                         color: #ED1536
    //     .line
    //         border: 1px solid #E7EEF7
    //         width: 100%
    //         height: 0px
    //     .action-bar
    //         text-align: right
    //         padding-right: 30px
    //         .error
    //             color: #ED1536
    //             font: 15px AvenirMedium
    //             letter-spacing: 0px
    //             font-weight: 800
    //             float: left
    //             padding: 12px 26px
    //         .submit
    //             width: 146px
    //             height: 40px
    //             background: #11A1FD 0% 0% no-repeat padding-box
    //             font: 15px AvenirMedium
    //             letter-spacing: 0px
    //             color: #FFFFFF
    //             border-radius: 5px
    //         .cancel
    //             margin-right: 40px
    //             border: none
    //             font: 15px AvenirMedium
    //             letter-spacing: 0px
 }    //             color: #7D9EB5
.outlet-update {
    .variants {
        margin: 15px;
        padding: 15px;
        border-radius: 8px;
        border: 1px solid #D9D9D9;
        background: #FFF;
        box-shadow: 0px 4px 16px 0px rgba(198, 198, 198, 0.12);
        .row-header {
            color: #707070;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 14px; }
        .checkbox {
            margin-right: 12px;
            width: 18px;
            height: 18px;
            border-radius: 4px;
            border: 1px solid #BAC3D0;
            background: #FFF;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            .selected {
                height: 14px;
                width: 14px;
                border-radius: 4px;
                background: #121212; } }
        .row-item {
            height: 46px;
            // margin: 14px 0px
            align-items: center;
            border-top: 1px solid #D9D9D9; }
        .row-item {
            input {
                text-align: center;
                height: 26px;
                border-radius: 4px;
                border: 1px solid #D9D9D9; }
            input[type="number"]::-webkit-outer-spin-button,
            input[type="number"]::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0; }
            input[type="number"] {
                -moz-appearance: textfield; } }
        .row-item:last-child {
            margin-bottom: 0px; } }
    .ant-drawer-body, .ant-drawer-footer {
        border-color: #FFF;
        background: #FFF; }
    .ant-drawer-body {
        padding: 0px;
        overflow: hidden; }
    .header {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #E5E5E5;
        padding: 0px 17px;
        .title {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #000000;
            margin-left: 16px; }
        .arrow {
            cursor: pointer;
            color: #000;
            font-size: 24px; } }
    .content {
        margin-top: 30px;
        // display: flex
        // justify-content: center
        // flex-wrap: wrap
        // .title
        //     color: #FFF
        //     font: 900 24px AvenirMedium
        //     text-transform: uppercase
        //     margin-bottom: 0px !important
        .input-validation {
            .ant-switch {
                background-color: #ffffffbf; }
            .ant-switch-checked {
                background-color: #34E5C1; } }
        .back {
            position: absolute;
            color: #fff;
            top: 24px;
            left: 16px;
            font-size: 24px;
            cursor: pointer; }
        .group {
            padding: 0px 52px;
            width: 100%;
            @media screen and (min-width: 900px) and (max-width: 1400px) {
                margin-bottom: 22px; }
            @media screen and (min-width: 1400px) {
                margin-bottom: 18px; }
            .ant-switch-handle {
                top: 4px; }
            .suggestions {
                overflow-y: auto;
                max-height: 300px;
                position: absolute;
                background: #fff;
                width: 78%;
                z-index: 1;
                border: 1px solid #a7a7a7;
                border-radius: 4px;
                margin-top: 2pxss;
                .item {
                    padding: 8px 15px;
                    border: 0px solid #ececec;
                    .main {
                        font: 14px AvenirHeavy;
                        letter-spacing: 0px;
                        color: #000000;
                        line-height: 2;
                        .secondary {
                            font: 12px AvenirMedium;
                            letter-spacing: 0px;
                            color: #000000;
                            .item:hover {
                                opacity: 0.6;
                                cursor: pointer; } } } } }
            .ant-input-affix-wrapper {
                display: flex;
                align-items: center;
                padding: 0px 11px; }
            .item {
                padding: 8px 15px;
                border: 0px solid #ececec;
                .main {
                    font: 14px AvenirHeavy;
                    letter-spacing: 0px;
                    color: #000000;
                    line-height: 2; }
                .secondary {
                    font: 12px AvenirMedium;
                    letter-spacing: 0px;
                    color: #000000; } }
            .item:hover {
                opacity: 0.6;
                cursor: pointer; }
            .ant-input-suffix {
                margin-top: 0px; }
            .label {
                height: 19px;
                text-align: left;
                font-size: 16px;
                letter-spacing: 0px;
                color: #4E4E4E;
                margin-bottom: 8px; }
            input {
                width: 100%;
                // height: 40px
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border-radius: 5px; }
            .ant-select {
                width: 100%;
                height: 40px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border-radius: 4px; }
            .ant-switch:focus:hover {
                box-shadow: 0 0 0 1px #ccc; }
            .ant-switch {
                width: 52px;
                height: 26px;
                box-shadow: 0 0 0 1px #ccc; }
            .ant-switch-loading-icon, .ant-switch::after {
                width: 22px;
                height: 22px; }
            .add-product {
                &.ant-select {
                    width: 100%;
                    height: 50px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border-radius: 4px; }
                .ant-select-selector {
                    height: 50px !important;
                    padding: 8px 14px;
                    justify-content: flex-start;
                    .ant-select-selection-item {
                        height: 40px;
                        width: 100%;
                        background: #fff;
                        border: 1px solid #fff;
                        font: 14px AvenirMedium;
                        .ant-select-selection-item-content {
                            width: 100%; } }
                    .ant-select-selection-search {
                        position: absolute;
                        right: 0; } } } } }
    .ant-drawer-footer {
        height: 66px;
        border-top: 1px solid #E5E5E5;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .footer {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .add-price {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 5px 15px #758BFD63;
                border-radius: 4px;
                font: 12px AvenirMedium;
                letter-spacing: 0px;
                color: #000000;
                width: 100%; }
            .cancel, .save {
                height: 100%;
                width: 100px;
                font-size: 14px;
                border-radius: 4px; }
            .save {
                background: #000000 0% 0% no-repeat padding-box;
                color: #fff; }
            .cancel {
                margin-right: 16px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #4E4E4E;
                color: #4E4E4E; } } } }
