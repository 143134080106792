.sitemap {
    .action-bar {
        padding: 15px 24px;
        background: #f5f5f5;
        height: 60px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #E7EEF7;
        text-align: right;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .filter {
            flex-grow: unset;
            width: 370px;
            height: 40px;
            border-radius: 6px;
            border: 1px solid #D9D9D9; } }
    .sm-container {
        border-radius: 4px;
        border: 1px solid #D8DEE6;
        background: #FFF;
        .header {
            height: 40px;
            font-size: 14px;
            letter-spacing: 0px;
            color: #404040;
            padding: 0px;
            margin-top: 15px;
            margin-left: 30px;
            margin-right: 30px; }
        .list {
            overflow-y: auto;
            height: calc( 100vh - 220px );
            .item:hover {
                background: #f5f5f5;
                cursor: pointer; }
            .item {
                cursor: default;
                background: #fff;
                padding: 0px 30px;
                display: flex;
                align-items: center;
                height: 64px;
                border-top: 1px solid #f1f1f1; } } } }
