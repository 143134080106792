.product {
    // padding: 14px 28px
    // .ant-select
    //     min-width: 200px
    //     border-radius: 5px
    //     border-width: 0px
    //     .ant-select-multiple .ant-select-selector, .ant-select-selector
    //         border-radius: 5px !important
    display: flex;
    align-items: center;
    justify-content: center;
    .head {
        font-size: 14px;
        letter-spacing: 0px;
        color: #000000;
        height: 26px;
        .link {
            font-size: 12px;
            letter-spacing: 0px;
            color: #11A1FD;
            cursor: pointer; }
        .path-arrow {
            color: #697B95;
            font-size: 12px;
            padding: 0px 8px 0px 4px; } }
    .title {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0px;
        color: #000000; }
    .list {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #96A9C340;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        .footer {
            color: #141414;
            font-size: 12px;
            font-weight: 500;
            background: #F5F5F5;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 0px; }
        .action-bar {
            background: #f5f5f5;
            height: 60px;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: #E7EEF7;
            text-align: right;
            padding: 0px 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .date-range {
                position: relative;
                background: #FFFFFF;
                border: 1px solid #ccc;
                border-radius: 5px;
                height: 40px;
                padding-left: 20px;
                display: flex;
                justify-content: center;
                .ant-picker {
                    border: none; }
                .ant-picker-input {
                    width: 108px; } }
            .actions {
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-width: 430px; }
            .title {
                font-size: 14px;
                letter-spacing: 0px;
                color: #404040;
                float: left;
                margin: 10px 25px;
                line-height: 2em; }
            .multi-select {
                width: 240px; }
            .btn {
                // border-radius: 5px
                // height: 30px
                // font-size: 12px
                // width: 180px
                margin: 0px 10px;
                // color: #fff
                // background: #000000 0% 0% no-repeat padding-box
                // padding: 0px
                // font-weight: 500
                // letter-spacing: -0.032em
                // box-shadow: 0px 0px 12px #96a9c340
                // border: 0px
 } }                // line-height: 2rem
        .content {
            border-radius: 4px;
            border: 1px solid #D8DEE6;
            background: #FFF;
            .header {
                height: 40px;
                font-size: 14px;
                letter-spacing: 0px;
                color: #404040;
                padding: 0px;
                margin-top: 15px;
                margin-left: 30px;
                margin-right: 30px;
                .ant-col {
 } }                    // padding-right: 20px
            .filters {
                margin-top: 7px;
                margin-left: 30px;
                margin-bottom: 15px;
                margin-right: 30px;
                .ant-input-suffix {
                    @media screen and (min-width: 900px) and (max-width: 1400px) {
                        line-height: 1; }
                    @media screen and (min-width: 1400px) {
                        line-height: 2; }
                    img {
                        @media screen and (min-width: 900px) and (max-width: 1400px) {
                            width: 15px;
                            height: 20px; }
                        @media screen and (min-width: 1400px) {
                            width: 20px;
                            height: 24px; } } }
                .ant-col {
                    padding-right: 20px; }
                // .ant-input-affix-wrapper
                //     background: #FFFFFF 0% 0% no-repeat padding-box
                //     border: 1px solid #ccc
                //     border-radius: 5px
                // .ant-select
                //     width: 100%
                //     height: 34px
                //     .ant-select-selector
                //         height: 30px
 }                //         border-radius: 0px
            .contains {
                overflow-y: auto;
                height: calc( 100vh - 220px );
                .item:hover {
                    background: #f5f5f5;
                    cursor: pointer; }
                .item {
                    cursor: default;
                    background: #fff;
                    padding: 0px 30px;
                    display: flex;
                    align-items: center;
                    height: 64px;
                    border-top: 1px solid #f1f1f1;
                    input {
                        text-align: center;
                        height: 26px;
                        border-radius: 4px;
                        border: 1px solid #D9D9D9; }
                    input[type="number"]::-webkit-outer-spin-button,
                    input[type="number"]::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0; }
                    input[type="number"] {
                        -moz-appearance: textfield; }
                    .category {
                        padding: 2px;
                        display: -webkit-box;
                        max-width: max-content;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        .ant-badge {
                            margin: 2px 4px; }
                        .ant-badge-not-a-wrapper .ant-badge-count {
                            background: #DBE2EA6C;
                            color: #000;
                            border-radius: 4px;
                            height: 26px;
                            padding: 4px 10px; } }
                    .instock {
                        color: #07C180; }
                    .outOfStock {
                        color: #ED1536;
                        font-weight: 600; } } } }
        .active {
            width: 2px;
            height: 100%;
            background-color: #46b275;
            float: left;
            top: 0px;
            left: 0px;
            position: absolute; }
        .inactive {
            width: 2px;
            height: 100%;
            background-color: #a8abbd;
            float: left;
            top: 0px;
            left: 0px;
            position: absolute; } } }
.product-view {
    // padding: 0px 28px
    .create-option-popup {
        .container {
            .box {
                background: #FFF;
                width: 845px;
                .title {
                    padding: 17px 16px;
                    margin: 0px;
                    font-size: 16px;
                    font-weight: 600; }
                .content {
                    padding: 15px 24px; } }
            .action {
                gap: 15px;
                .no {
                    width: auto;
                    border-radius: 4px;
                    border: 1px solid #D9D9D9;
                    background: #FFF;
                    color: #121212;
                    font-size: 13px;
                    font-weight: 500; }
                .yes {
                    width: auto;
                    border-radius: 4px;
                    background: #121212;
                    color: #fff;
                    font-size: 13px;
                    font-weight: 500; } }
            .create-option {
                width: 100%;
                .option-name, .option-values {
                    width: 100%;
                    position: relative; }
                .option-list {
                    border: 1px solid #c7c7c7;
                    width: 100%;
                    margin-top: 4px;
                    position: absolute;
                    padding: 10px 14px;
                    background: #fff;
                    border-radius: 6px;
                    z-index: 1;
                    .option:hover {
                        cursor: pointer;
                        color: #707070; } }
                .label {
                    color: #4E4E4E;
                    font-size: 13px;
                    font-weight: 500; }
                input {
                    width: 100%;
                    border: 1px solid #BAC3D0;
                    height: 44px;
                    padding: 0px 12px;
                    flex-grow: 1; } } } }
    .product-variants {
        width: 1145px;
        .options {
            padding: 15px;
            background: #FFFFFF;
            border-radius: 8px;
            border: 1px solid #D9D9D9;
            box-shadow: 0px 4px 16px 0px #C6C6C61F;
            .items {
                .option {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-grow: 1;
                    .data {
                        cursor: pointer;
                        border-radius: 4px;
                        border: 1px solid #D9D9D9;
                        background: #FFF;
                        display: flex;
                        padding: 10px;
                        flex-grow: 1;
                        justify-content: flex-start;
                        text-transform: capitalize;
                        .name {
                            color: #000;
                            font-size: 14px;
                            font-weight: 500;
                            width: 90px; }
                        .values {
                            background: #FFF;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 10px;
                            flex-wrap: wrap;
                            .value {
                                color: #707070;
                                padding: 2px 14px 0px 14px;
                                border-radius: 44px;
                                font-size: 13px;
                                font-weight: 500;
                                border: 1px solid #D9D9D9; } } } } }
            .title {
                font-size: 15px;
                font-weight: 600; }
            .add-option {
                font-size: 13px;
                font-weight: 600;
                cursor: pointer; } }
        .content {
            display: flex;
            gap: 12px;
            margin-top: 12px;
            .variants-combination {
                padding: 15px;
                width: 348px;
                min-height: 101px;
                height: max-content;
                background: #FFFFFF;
                border-radius: 8px;
                border: 1px solid #D9D9D9;
                box-shadow: 0px 4px 16px 0px #C6C6C61F;
                .title {
                    font-size: 15px;
                    font-weight: 600; }
                .filter {
                    margin: 15px 0px; }
                .group-by {
                    width: max-content;
                    margin-right: 8px; }
                .group-by-select {
                    width: 100px;
                    text-transform: capitalize;
                    border-radius: 50px; }
                .group-by-name {
                    .permutation-combination {
                        display: flex;
                        gap: 10px;
                        flex-wrap: wrap;
                        .permutation-combination-items {
                            color: #707070;
                            padding: 2px 14px 0px 14px;
                            overflow: hidden;
                            display: flex;
                            gap: 10px;
                            border-radius: 40px;
                            border: 1px solid #D9D9D9;
                            .permutation-combination-item::after {
                                content: "/";
                                margin-left: 10px; }
                            .permutation-combination-item:last-child:after {
                                content: "";
                                margin-left: 0px; }
                            .permutation-combination-item {
                                cursor: pointer;
                                font-size: 13px;
                                font-weight: 500;
                                text-transform: capitalize; } } }
                    .name {
                        color: #404040;
                        font-weight: 600;
                        font-size: 14px;
                        margin-bottom: 10px;
                        display: flex;
                        gap: 6px;
                        img {
                            cursor: pointer; } }
                    .selected-permutation-combination-item {
                        background: #121212;
                        color: #FFF !important; }
                    .delete-selected-permutation-combination-item {
                        border: 1px solid #121212;
                        background: #121212 !important;
                        color: #fff !important;
                        font-size: 13px;
                        font-weight: 500;
                        text-decoration: line-through; }
                    .delete-variant {
                        border: 1px solid #D9D9D9;
                        background: #F8F8F8 !important;
                        color: #B2B2B2 !important;
                        font-size: 13px;
                        font-weight: 500;
                        text-decoration: line-through; } }
                .divider {
                    background: #D9D9D9;
                    height: 1px;
                    width: 100%;
                    margin: 15px 0px; } }
            .selected-variant {
                width: 785px;
                .empty {
                    padding: 54px 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column; }
                .title {
                    font-size: 15px;
                    font-weight: 500;
                    text-transform: capitalize; }
                .media, .inventory, .pricing, .deleted-variant-block {
                    padding: 15px;
                    background: #FFFFFF;
                    border-radius: 8px;
                    border: 1px solid #D9D9D9;
                    box-shadow: 0px 4px 16px 0px #C6C6C61F;
                    margin-bottom: 12px;
                    .title {
                        font-size: 15px;
                        font-weight: 600; } }
                .deleted-variant-block {
                    padding: 51px 0px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .option-variant {
                        font-size: 18px;
                        font-weight: 600;
                        margin: 25px 0px 12px 0px; }
                    .add-option-variant {
                        cursor: pointer;
                        padding: 7px 18px;
                        border: 1px solid #D9D9D9;
                        font-size: 14px;
                        font-weight: 600;
                        border-radius: 4px; } } } } }
    .ant-drawer-body, .ant-drawer-footer {
        background: #FFF; }
    .ant-drawer-body {
        padding: 0px;
        overflow: hidden; }
    .header {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #E5E5E5;
        padding: 0px 17px;
        .title {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #000000;
            margin-left: 16px; }
        .arrow {
            cursor: pointer;
            color: #000;
            font-size: 24px; } }
    .content {
        // box-shadow: 0px 0px 10px #96A9C340
        // border-radius: 5px
        width: 100%;
        height: 100%;
        .tab-menus {
            display: flex;
            color: #000;
            align-items: baseline;
            align-self: stretch;
            flex: 1 0 auto;
            justify-content: flex-start;
            max-width: 100%;
            // margin-top: 6px
            flex-direction: row;
            border-bottom: 1px solid #D9D9D9;
            height: 50px;
            padding: 0px 57px;
            .label {
                display: flex;
                flex-direction: column;
                margin-right: 40px;
                position: relative;
                cursor: pointer;
                justify-content: center;
                align-items: center;
                height: 50px; }
            .selected {
                color: #07c180; }
            .underline {
                width: 100%;
                position: absolute;
                bottom: 0px;
                border-bottom: 2px solid #07c180; } }
        .ant-select-selector {}
        .details {
            padding: 6px 40px;
            .basic-info {
                font-size: 22px;
                font-weight: 900;
                letter-spacing: 0px;
                color: #fff; }
            .group {
                margin: 16px 0px;
                .markdown {
                    margin-top: 10px;
                    border: 1px solid #ADB7C3;
                    border-radius: 4px;
                    overflow: hidden;
                    .preview .rc-md-editor {
                        border: none; } }
                .label {
                    height: 19px;
                    text-align: left;
                    font-size: 16px;
                    letter-spacing: 0px;
                    color: #4E4E4E;
                    margin-bottom: 8px; }
                .value {
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 1px solid #ADB7C3;
                    border-radius: 4px;
                    resize: none;
                    width: 100%;
                    img {
                        cursor: pointer; } }
                .outlets {
                    height: 200px;
                    overflow-y: auto;
                    .item {
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        border: 1px solid #ADB7C3;
                        border-radius: 5px;
                        padding: 16px 10px;
                        .name {
                            font-size: 16px;
                            color: #071E3D; }
                        .label {
                            font-size: 14px;
                            color: #576B83; }
                        .value {
                            font-size: 14px;
                            color: #00BDFF;
                            border: none; }
                        .instock {
                            font-size: 14px;
                            color: #07C180; }
                        .outstock {
                            font-size: 14px;
                            color: #ED1536; } } } } }
        .line {
            border: 1px solid #E7EEF7;
            width: 100%;
            height: 0px; }
        .action-bar {
            text-align: left;
            padding-left: 40px;
            // .save
            //     width: 165px
            //     background: #FFF
            //     color: #000
            .error {
                color: #ED1536;
                font-size: 15px;
                letter-spacing: 0px;
                font-weight: 800;
                float: left;
                padding: 12px 26px; }
            // .submit
            //     width: 146px
            //     height: 40px
            //     background: #11A1FD 0% 0% no-repeat padding-box
            //     font-size: 15px
            //     letter-spacing: 0px
            //     color: #FFFFFF
            //     border-radius: 5px
            // .cancel
            //     margin-right: 40px
            //     border: none
            //     font-size: 15px
            //     letter-spacing: 0px
 } } }            //     color: #7D9EB5
.product-img-uploader {
    .ant-upload-select-picture-card {
        width: 130px !important;
        height: 130px !important;
        border-radius: 6px !important;
        border: 1px dashed #707070 !important;
        background: #FFF !important; }
    .ant-upload-list-picture-card-container {
        width: 130px !important;
        height: 130px !important;
        border-radius: 6px !important;
        border: 1px dashed #707070 !important; }
    .ant-upload-list-picture-card .ant-upload-list-item {
        padding: 4px !important; }
    .ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {
        border: none !important; } }
