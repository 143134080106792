.category {
    .ant-select-selector {
        display: flex;
        align-items: center;
        justify-content: center; }
    .head {
        font: 14px/19px AvenirMedium;
        letter-spacing: 0px;
        color: #000000;
        height: 26px;
        .link {
            font: 12px/16px AvenirMedium;
            letter-spacing: 0px;
            color: #11A1FD;
            cursor: pointer; }
        .path-arrow {
            color: #697B95;
            font-size: 12px;
            padding: 0px 8px 0px 4px; } }
    .title {
        font: normal normal 900 18px/25px AvenirMedium;
        letter-spacing: 0px;
        color: #000000; }
    .list {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #96A9C340;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        .action-bar {
            background: #f5f5f5;
            height: 60px;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: #E7EEF7;
            text-align: right;
            padding: 0px 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .actions {
                display: flex;
                justify-content: space-between;
                align-items: center; }
            .title {
                font: 14px AvenirMedium;
                letter-spacing: 0px;
                color: #404040;
                float: left;
                margin: 10px 25px;
                line-height: 2em; }
            .multi-select {
                width: 240px; }
            .btn {
                // border-radius: 5px
                // height: 30px
                // font-size: 12px
                // width: 180px
                margin: 0px 10px;
                // color: #fff
                // background: #000000 0% 0% no-repeat padding-box
                // padding: 0px
                // font-weight: 500
                // letter-spacing: -0.032em
                // box-shadow: 0px 0px 12px #96a9c340
                // border: 0px
                // font-family: 'AvenirMedium'
 } }                // line-height: 2rem
        .content {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            .header {
                font: 14px AvenirMedium;
                letter-spacing: 0px;
                color: #404040;
                padding: 0px;
                margin-top: 15px;
                margin-left: 30px;
                margin-right: 30px;
                .ant-col {
                    padding-right: 20px; } }
            .filters {
                margin-top: 7px;
                margin-left: 30px;
                margin-bottom: 15px;
                margin-right: 30px;
                .ant-input-suffix {
                    @media screen and (min-width: 900px) and (max-width: 1400px) {
                        line-height: 1; }
                    @media screen and (min-width: 1400px) {
                        line-height: 2; }
                    img {
                        @media screen and (min-width: 900px) and (max-width: 1400px) {
                            width: 15px;
                            height: 20px; }
                        @media screen and (min-width: 1400px) {
                            width: 20px;
                            height: 24px; } } }
                .ant-col {
                    padding-right: 20px; }
                // .ant-input-affix-wrapper
                //     background: #FFFFFF 0% 0% no-repeat padding-box
                //     border: 1px solid #ccc
                //     border-radius: 5px
                // .ant-select
                //     width: 100%
                //     height: 34px
                //     .ant-select-selector
                //         height: 30px
 }                //         border-radius: 0px
            .contains {
                overflow-y: auto;
                .item {
                    padding: 0px 30px;
                    display: flex;
                    align-items: center;
                    height: 56px;
                    border-top: 1px solid #f1f1f1;
                    .ant-col {
                        // padding: 10px 0px
                        opacity: 1;
                        padding-right: 20px; }
                    .ant-col:hover {
                        opacity: 0.6;
                        cursor: pointer; }
                    .description {
                        height: 48px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical; }
                    .categories {
                        padding: 0px;
                        .ant-badge {
                            margin: 0px 4px; }
                        .ant-badge-not-a-wrapper .ant-badge-count {
                            background: #DBE2EA6C;
                            color: #000;
                            border-radius: 4px;
                            height: 26px;
                            padding: 3px 10px; } }
                    .instock {
                        color: #07C180; }
                    .outstock {
                        color: #ED1536; } } } }
        .active {
            width: 2px;
            height: 100%;
            background-color: #46b275;
            float: left;
            top: 0px;
            left: 0px;
            position: absolute; }
        .inactive {
            width: 2px;
            height: 100%;
            background-color: #a8abbd;
            float: left;
            top: 0px;
            left: 0px;
            position: absolute; } } }
.category-view {
    padding: 0px 28px;
    .header {
        font-size: 12px;
        padding: 22px 0px;
        .link {
            cursor: pointer;
            font: 14px/19px AvenirMedium;
            letter-spacing: 0px;
            color: #11A1FD; }
        .head {
            font: 14px/19px AvenirMedium;
            letter-spacing: 0px;
            color: #000000; }
        .title {
            font: normal normal 900 18px/25px AvenirMedium;
            letter-spacing: 0px;
            color: #000000; } }
    .content {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #96A9C340;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        .details {
            padding: 36px 40px;
            .basic-info {
                font: normal normal 900 16px/22px AvenirMedium;
                letter-spacing: 0px;
                color: #697B95; }
            .group {
                margin: 16px 0px;
                .label {
                    font: 15px/20px AvenirMedium;
                    letter-spacing: 0px;
                    color: #ADB7C3;
                    padding: 0px;
                    margin: 0px;
                    font-size: 15px; }
                .value {
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 1px solid #ADB7C3;
                    border-radius: 4px;
                    resize: none;
                    width: 100%;
                    margin-top: 12px;
                    img {
                        cursor: pointer; } }
                .outlets {
                    height: 200px;
                    overflow-y: auto;
                    .item {
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        border: 1px solid #ADB7C3;
                        border-radius: 5px;
                        padding: 16px 10px;
                        .name {
                            font: 16px/22px AvenirMedium;
                            letter-spacing: -0.51px;
                            color: #071E3D; }
                        .label {
                            font: 14px/22px AvenirMedium;
                            letter-spacing: -0.45px;
                            color: #576B83; }
                        .value {
                            font: 14px/22px AvenirMedium;
                            letter-spacing: -0.45px;
                            color: #00BDFF;
                            border: none; }
                        .instock {
                            font: 14px/22px AvenirMedium;
                            letter-spacing: -0.45px;
                            color: #07C180; }
                        .outstock {
                            font: 14px/22px AvenirMedium;
                            letter-spacing: -0.45px;
                            color: #ED1536; } } } } }
        .line {
            border: 1px solid #E7EEF7;
            width: 100%;
            height: 0px; }
        .action-bar {
            text-align: right;
            padding-right: 30px;
            .error {
                color: #ED1536;
                font-size: 15px;
                letter-spacing: 0px;
                font-weight: 800;
                float: left;
                padding: 12px 26px; }
            .submit {
                width: 146px;
                height: 40px;
                background: #11A1FD 0% 0% no-repeat padding-box;
                font-size: 15px;
                letter-spacing: 0px;
                color: #FFFFFF;
                border-radius: 5px; }
            .cancel {
                margin-right: 40px;
                border: none;
                font-size: 15px;
                letter-spacing: 0px;
                color: #7D9EB5; } } } }
.category-save {
    .ant-drawer-body, .ant-drawer-footer {
        background: #FFF; }
    .ant-drawer-footer {
        height: 66px;
        border-top: 1px solid #E5E5E5;
        display: flex;
        justify-content: flex-end;
        align-items: center; }
    .ant-drawer-body {
        padding: 0px;
        overflow: hidden; }
    .header {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #E5E5E5;
        padding: 0px 17px;
        .title {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #000000;
            margin-left: 16px; }
        .arrow {
            cursor: pointer;
            color: #000;
            font-size: 24px; } }
    .content {
        padding: 24px 52px;
        display: flex;
        flex-direction: column;
        .back {
            position: absolute;
            color: #121212;
            top: 24px;
            left: 16px;
            font-size: 24px;
            cursor: pointer; }
        .group {
            margin-bottom: 30px;
            width: 100%;
            .category-icon {
                position: relative;
                .action {
                    position: absolute;
                    display: flex;
                    background: #00000000;
                    justify-content: space-evenly;
                    width: 70px;
                    height: 70px;
                    align-items: center;
                    color: #FFFFFF00; }
                .action:hover {
                    z-index: 1;
                    color: #FFF;
                    background: #0000008a; } }
            .icon {
                width: 70px;
                object-fit: contain;
                height: 70px; }
            .image-upload {
                width: 55px;
                height: 55px;
                border: 1px dashed #121212;
                border-radius: 4px;
                background: #fff;
                text-align: center;
                padding-top: 14px;
                cursor: pointer; }
            .suggestions {
                overflow-y: auto;
                max-height: 300px;
                position: absolute;
                background: #fff;
                width: 78%;
                z-index: 1;
                border: 1px solid #a7a7a7;
                border-radius: 4px;
                margin-top: 2pxss;
                .item {
                    padding: 8px 15px;
                    border: 0px solid #ececec;
                    .main {
                        font: 14px/18px AvenirHeavy;
                        letter-spacing: 0px;
                        color: #000000;
                        line-height: 2;
                        .secondary {
                            font: 12px/16px AvenirMedium;
                            letter-spacing: 0px;
                            color: #000000;
                            .item:hover {
                                opacity: 0.6;
                                cursor: pointer; } } } } }
            .ant-input-affix-wrapper {
                padding: 0px 11px; }
            .item {
                padding: 8px 15px;
                border: 0px solid #ececec;
                .main {
                    font: 14px/18px AvenirHeavy;
                    letter-spacing: 0px;
                    color: #000000;
                    line-height: 2; }
                .secondary {
                    font: 12px/16px AvenirMedium;
                    letter-spacing: 0px;
                    color: #000000; } }
            .item:hover {
                opacity: 0.6;
                cursor: pointer; }
            .ant-input-suffix {
                margin-top: 4px; }
            .label {
                height: 19px;
                text-align: left;
                font-size: 16px;
                letter-spacing: 0px;
                color: #4E4E4E;
                margin-bottom: 8px; }
            input {
                width: 100%;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border-radius: 4px; }
            .ant-select {
                width: 100%;
                height: 30px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border-radius: 4px; }
            .add-product {
                &.ant-select {
                    width: 100%;
                    height: 50px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border-radius: 4px; }
                .ant-select-selector {
                    height: 50px;
                    padding: 4px;
                    .ant-select-selection-item {
                        height: 40px;
                        width: 100%;
                        background: #fff;
                        border: 1px solid #fff;
                        font: 14px/19px AvenirMedium;
                        .ant-select-selection-item-content {
                            width: 100%; } }
                    .ant-select-selection-search {
                        position: absolute;
                        right: 0; } } } } }
    .footer {
        width: 100%;
        justify-content: space-around;
        display: flex;
        .add-price {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 5px 15px #758BFD63;
            border-radius: 4px;
            font: 12px/16px AvenirMedium;
            letter-spacing: 0px;
            color: #000000;
            min-width: 156px;
            width: auto; }
        .cancel {
            background: #000000 0% 0% no-repeat padding-box;
            box-shadow: 0px 0px 12px #96A9C340;
            border: 1px solid #F8F8F8;
            border-radius: 4px;
            font: 12px/22px AvenirMedium;
            letter-spacing: -0.38px;
            color: #FFFFFF;
            text-transform: capitalize;
            min-width: 156px;
            width: auto; }
        .update {
            background: #33E1BE 0% 0% no-repeat padding-box;
            box-shadow: 0px 0px 12px #96A9C340;
            border-radius: 4px;
            font: 12px/22px AvenirMedium;
            letter-spacing: -0.38px;
            color: #FFFFFF;
            text-transform: capitalize;
            min-width: 156px;
            width: auto; } } }
.image-upload {
    .ant-tabs-tab-next, .ant-tabs-tab-prev {
        display: none; }
    .ant-modal-footer {
        display: flex;
        justify-content: flex-end;
        padding: 16px; }
    .upload {
        background: #34E5C1 0% 0% no-repeat padding-box;
        border-radius: 3px;
        font: 13px/18px AvenirMedium;
        letter-spacing: 0px;
        color: #ffffff;
        border: none;
        width: 140px;
        &.ant-btn:hover, .ant-btn:focus, .ant-btn:active, .ant-btn.active {
            text-decoration: none;
            background: #34E5C1;
            color: #ffffff; } }
    .cancel {
        width: 140px;
        border: none;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 3px;
        font: 13px/18px AvenirMedium;
        letter-spacing: 0px;
        color: #697B95;
        box-shadow: none;
        &.ant-btn:hover, .ant-btn:focus, .ant-btn:active, .ant-btn.active {
            text-decoration: none;
            background: #ffffff;
            color: #697B95; } }
    .ant-tabs-nav-wrap,  .ant-tabs-nav .ant-tabs-tab:hover, {
        font: 14px/19px AvenirMedium;
        letter-spacing: 0px;
        color: #697B95;
        cursor: pointer;
        .ant-tabs-nav .ant-tabs-tab-active {
            color: #000000; }
        .ant-tabs-ink-bar {
            height: 1px;
            background: #000000 0% 0% no-repeat padding-box;
            sborder-radius: 11px; } }
    .ant-tabs-content {
        margin: 10px 0px;
        .from-local {
            .preview-image {
                object-fit: contain;
                width: 60px;
                height: 60px;
                background: #CFE0EB 0% 0% no-repeat padding-box;
                border-radius: 4px; }
            .ant-upload.ant-upload-drag {
                border: none;
                background: #fff; }
            .ant-upload-text, .ant-upload-hint {
                font: normal normal medium 14px/19px Avenir;
                letter-spacing: 0px;
                color: #CCD0DD; }
            .select-icn {
                width: 150px;
                height: 30px;
                background: #ECEEF2 0% 0% no-repeat padding-box;
                border: 1px solid #E5E5E5;
                border-radius: 3px;
                font: 13px/18px AvenirMedium;
                letter-spacing: 0px;
                color: #697B95;
                padding: 6px 16px; } }
        .pre-icons {
            .search {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #ADB7C3;
                border-radius: 4px;
                .ant-input-suffix {
                    img {
                        width: 22px;
                        height: 22px;
                        color: #ADB7C3;
                        position: absolute;
                        right: 6px;
                        top: 7px; } } }
            .pics {
                display: flex;
                max-height: 200px;
                width: 100%;
                overflow-y: auto;
                flex-wrap: wrap;
                div {
                    border-width: 2px;
                    border-style: dotted;
                    border-color: #00000000;
                    width: 60px;
                    height: 60px;
                    border-radius: 4px;
                    margin-right: 8px;
                    margin-top: 8px;
                    img {
                        width: 56px;
                        height: 56px;
                        border-radius: 4px;
                        object-fit: cover; } } } } }
    .ant-modal-body {
        padding: 0px 20px; }
    .ant-modal-header {
        background: #000000 0% 0% no-repeat padding-box;
        color: #ffffff;
        height: 45px;
        padding: 10px 18px;
        .ant-modal-title {
            font: 18px AvenirMedium;
            letter-spacing: 0px;
            color: #FFFFFF;
            text-transform: capitalize; } }
    .ant-modal-close {
        top: 10px;
        .ant-modal-close-x {
            color: #FFFFFF; } } }
